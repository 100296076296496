$container-size: 900px;
$bg: #fff;
$text: #333;

$basefont: 'Open Sans', sans-serif;




//General colours
$colors_lightgray:  #ececec;
$color_titles: #555B68;
$colors_signpost_primary: #2051C2;


$colors_blue: #2051C2;


//Concrete
$colors_concrete_dark: #454D5F;
$colors_concrete_primary: #2051C2;


//Super Useful Studio colours
$colors_superusefulstudio_lightestgreen: rgb(223, 230, 226);
$colors_superusefulstudio_green: #50C27B;
$colors_superusefulstudio_darkgreen: rgb(51, 94, 67);

//118118money
$colors_118118money_primary: #f9f9f9;

//KGBDeals
$colors_kgbdeals_primary:  #2051C2;

//Lucy Choi
$colors_lucychoi_primary: rgb(19, 19, 19);
$colors_lucychoi_secondary: white;

//dispo


//Bupa



//Common spacing
$sectionspacing: 80px;
$internalpanel_spacing: 40px;