@import "variables";
@import "reset";
@import "typography";




html,body{
    margin: 0;
    padding: 0;
}

 
html {
    background: $bg;
    color: $text;
    font-family: $basefont;
    line-height: 24px;
    scroll-behavior: smooth;
    font-size: 62.5%;
    

}


body{

    border: 0;
    box-sizing: border-box;
    background-color: white;
    box-sizing: border-box;
    width: 100vw;
}




.navbar{
    height: 100px;
    margin-left: 50px;
    margin-right: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    
    .navlogo{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 100px;

        a{
            display: block;
            margin: 0;
            padding: 0;
        }


        .sus_logo{

            display: block;
            height: 30px;
            padding-top: 5px;
        }

    }
    //the links
    nav{

        ul{
            display: flex;
            flex-direction: row;
            align-content: flex-start;
            list-style-type: none;
            list-style: none;
            li{
                padding: 0 30px;
            }
        }
    }
    
}


//THEME - Concrete Styling
#concrete{
    .hero{
        background-image: url('../images/concrete-girl-hero.png');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: left bottom;
        .hero__content{
            .skillscovered{
                background-color: $colors_concrete_primary;
            }
        }
    }
}


//THEME - KGBDeals Styling
#kgbdeals{
    .hero{
        background-color: $colors_kgbdeals_primary;

        .hero__content{
            .logo{
                img{
                    height: 70px;
                }
            }

            .title{
                color: white;
            }

            .skillscovered{
            
                background-color: white;
    
                span{
                    color: $colors_concrete_primary;
                }
            }
        }
    }
}


//THEME - LucyChoi Styling
#lucychoi{
    .hero{
        background-color: $colors_lucychoi_primary;

        .hero__content{
            .logo{
                img{
                    height: 70px;
                }
            }

            .title{
                color: white;
            }

            .date{
                color: $colors_lucychoi_secondary;
            }

            .skillscovered{
            
                background-color: lighten($colors_lucychoi_primary, 20%);
    
                span{
                    color: $colors_lucychoi_secondary;
                }
            }
        }
    }
}



//THEME - 118218 Dispo Styling
#dispo{
    .hero{
        background-color: lighten(black, 98%);

        .hero__content{
            .logo{
                img{
                    height: 50px;
                }
            }

            .title{
                color: $text;
            }

            .date{
                color: lighten($text, 40%);
            }

            .skillscovered{
            
                background-color: $colors_blue;
    
                span{
                    color: white;
                }
            }
        }
    }
}

//THEME - 118 118 Money Styling
#money{
    .hero{
        background-color: lighten(black, 98%);

        .hero__content{
            .logo{
           
                img{
                    height: 120px;
                }
            }

            .title{
                color: $text;
            }

            .date{
                color: lighten($text, 40%);
            }

            .skillscovered{
            
                background-color: $colors_blue;
    
                span{
                    color: white;
                }
            }
        }
    }
}



//THEME - 118 118 Money Styling
#bupa{
    .hero{
        background: linear-gradient(197.58deg, rgb(22, 88, 173) 11.49%, rgb(40, 109, 199) 100.62%);

        .hero__content{
            .logo{
           
                img{
                    height: 120px;
                }
            }

            .title{
                color: white;
            }

            .date{
                color: lighten($text, 40%);
            }

            .skillscovered{
            
                background-color: white;
    
                span{
                    color: $colors_blue;
                }
            }
        }
    }
}




.hero{
    height: 40vh;
    min-height: 500px;
    max-height: 500px;
    width: 100vw;
    background-color: #f8f8f8;
    margin-bottom: $sectionspacing;
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    img{
        height: 100%;
        display: inline-block;
    }

    .hero__content{
        
        z-index: 1;
        //border: 1px solid pink;
        width:80%;
        max-width: 1000px;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        

        .logo{
            margin-bottom: 50px;
            img{
                height: 30px;
                display: block;  
            }
        }

        .title{
            font-family: 'Averta-Semibold';
            font-size: 3rem;
            margin-bottom: 20px;
            text-align: center;
            line-height: 3rem;;
        }

        .date{
            font-family: 'Averta-Semibold';
            font-size: 2rem;
            color: #A9B2C6;
            margin-bottom: 20px;
        }

        .skillscovered{
            
            display: inline-block;
            background-color: $colors_concrete_primary;
            border-radius: 4px;

            span{
                display: block;
                font-family: 'Averta-Semibold';
                font-size: 1.4rem;
                color: white;
                padding: 5px 15px;
            }
        }
    }
}












.container
{
    display: flex;
    //background-color: lightgoldenrodyellow;
    //border: 1px solid lightpink;
    justify-content: center;
    align-items: center;
    width:80%;
    max-width: 1000px;
    margin: 0 auto;
    flex-direction: column;




    .projectstats{
        display: block;
        width: 100%;
        //border: 1px solid pink;
        margin-bottom: $sectionspacing;

        .title{
            text-align: center;
            width: 100%;
            margin-bottom: 30px;
        }

        ul{
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: space-evenly;
            width: 100%;
            margin: 0;
            padding: 0;

            li{
                display: flex;
                list-style: none;
                width: 24%;
                height: auto;
                //border: 1px solid blue;
                align-content: center;
                justify-content: center;
                flex-direction: column;

                h5{
                    display: block;
                    text-align: center;
                    margin-bottom: 15px;
                    position: relative;
                    width: 100%;
                }

                hr{
                    display: block;
                    width: 30%;
                    color: $colors_lightgray;
                    align-self: center;
                }

                p{
                    display: block;
                    margin-top: 20px;
                    text-align: center;
                    width: 100%;
                }


            }
        }

    }//end projectstats



    .section{
        display: flex;
        flex-direction: row;
        width: 80%;
        max-width: 700px;
        margin-bottom: $sectionspacing;
        //border: 1px solid red;
        flex-wrap: wrap;



        .title{
            display: block;
            width: 100%;
            margin-bottom: 20px;
            text-align: center;
        }

        figure{
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            height: auto;
            margin: 10px 0 30px;
            
            
            img{
                margin-top: $internalpanel_spacing;
                display: block;
                width: 100%;
                height: 100%;
                margin: 0 auto;
                object-fit: cover;
            }




            figcaption{
                margin-top: 30px;
                margin-bottom: 30px;
            }
        }
        

        details{
            display: block;
            width: 100%;
            text-align: center;
        }
        

    }

    .listcontainer{
        

        .simplelist
        {
            display: flex;
            flex-direction: column;
            align-self: flex-start;
            width: 100%;
            


                li{

                    margin: 0;
                    padding: 0;
                    padding-bottom: 20px;
                    
    
                    span{
                        display: block;
                        width: 100%;
                    }
                }
                li:last-child{
                    padding-bottom: 0;
                }
        }
        .numbers{
            list-style-type: decimal;
        }
        
    }
    

    /*When you want a list or some content to be highlighted*/
    .signpost{
        background-color: $colors_signpost_primary;
        width: 100%;
        border-radius: 4px;
        margin-top: 30px;

        .listcontainer
        {
            padding: 30px;
        }
        
        *{
            color: white;
        }
    }

    //For wider images that sit outside the main container or a section
    .image_container{

        margin-bottom: $sectionspacing;
        max-width: 900px;

        img{
            display: block;
            width: 100%;
            height: auto;
        }
    }
    
}//end container




//for sinlge content pages e.g. about pages
.singlecol_content{
    width: 100vw;
    display: flex;
    background-color: #fefefe;
    background: linear-gradient(200.2deg, #F4F4F4 13.03%, #F9F9F9 100%);
    align-items: center;
    justify-content: center;
    border-left: 40px solid white;
    border-right: 40px solid white;
    border-bottom: 40px solid white;
    box-sizing: border-box;
    flex-direction: column;
    



    h1, h2, h3{
        margin-bottom: 40px;
    }

    .content{
        width: 75%;
        max-width: 700px;
        margin: 50px 0 50px;
        //border: 1px solid blue;

        .workexperience{
            display: flex;
            align-items: center;
            flex-direction: column;
            width: 100%;

            .wexp_entry{
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                width: 100%;
                //border: 1px solid pink;
                margin-bottom: $internalpanel_spacing;

                .wexp_whenwhere{
                    width: 30%;
                    min-width: 200px;
                    margin-right: 50px;

                    .date{
                        color: lighten($text, 30%);
                    }
                }
                .wexp_jobtitle{
                    width: 70%;
                    display: flex;
                    align-items: flex-start;
                    flex-direction: column;
                    .jobtitle{
                        margin-bottom: 0px;
                    }
                    a{
                        display: inline-block;
                        width: auto;
                        clear: left;
                    }
                    
                }
            }
        }
    }

    ul{
        margin: 30px 0 30px;
        padding: 30px;
        background-color: white;
        li{
            margin-bottom: 12px;
            color: $colors_superusefulstudio_darkgreen;
        }
        li:last-child{
            margin-bottom: 0;
        }
    }
}

.homehero{

    background: linear-gradient(199.66deg, #50C27B 0%, #379B5D 100%);

    .content{
        padding: 80px 0 80px;


        .title, p, h4{
            color: white;
        }
        p a{
            color: white;
            text-decoration: none;
        }

    }
}






.ui_presentaton_panel
{
    background-color: $colors_concrete_primary;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 80px;
    padding-bottom: 20px;
    margin-bottom: 60px;
    flex-wrap: wrap;
    align-items: center;
    
    
    .ui_container{

        width: 90%;
        max-width: 900px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        
    

        img{
            display: block;
            margin: 30px auto 60px;
            width: 100%;
            height: auto;
            border-radius: 8px;
        }

        .section{
            display: flex;
            justify-self: center;

        }
    }


    .section_2col
    {
        display: flex;
        flex-direction: row;
        width: 70%;
        max-width: 900px;

        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;

        .section_1col{
            width: 45%;
            .title{
                text-align: left;
                
            }
            p{
                text-align: left;
            }
        }
    }

    .section{
        display: flex;
        flex-direction: row;
        width: 90%;
        max-width: 900px;
        //border: 1px solid red;
        flex-wrap: wrap;
        margin-bottom: 30px;
            
        .title{
            display: block;
            width: 100%;
            margin-bottom: 20px;
            text-align: center;
            color: white;
            
        }
        p{
            display: block;
            margin: 0;
            padding: 0;
            width: 100%;
            color: white;
            text-align: center;
        }
        img{
            width: 100%;
            height: auto;
        }
    }

}//end ui_presentation_panel





footer{
    display: flex;
    justify-content: center;
    margin-top: 40px;
    margin-bottom: 40px;


    .content{
        width: 100%;
        max-width: 700px;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;

        a{
            img{
                display: inline-block;
                width: auto;
                height: 20px;
                filter: grayscale(100%);
            }
        }
        a:hover{
            img{
                filter: grayscale(0%);
            }
            
        }     
        
    }

}//end footer


.casestudies_container{

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .casestudies{
        width: 80%;
        max-width: 900px;
        margin-top: $internalpanel_spacing;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-row: 200px 200px;
        column-gap: 20px;
        grid-row-gap: 20px;  
        width: 100%;
        align-content: center;
        align-items: center;
        justify-content: center;
        
    
            .casestudy
            {
                background-color:#333;
                
                border-radius:1px;
                
                display:flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                height:400px;
                overflow: hidden;
                text-decoration: none;
    
                .logo{
                    display: flex;
                    flex-direction: column;
                    align-self: center;
                    align-content: center;
                    justify-content: center;
                    max-height: 100px;
                    min-height: 100px;
                    height: 100px;
                    //border: 1px solid hotpink;

     
                    img{
                        display: block;
                        margin: 0 auto;
                        width: 180px;
                        height: auto;
                        
                        //border: 1px solid green;
                    }

                }

                p{
                    
                    text-decoration: none;
                    text-align: center;
                    margin-top: 20px;
    
                }
           
            }
         
            .money{
                background-color: $colors_118118money_primary;
                p{
                    color: $text;
                }
            }
    
            .concrete{  
                background-color: $colors_concrete_dark;
                p{
                    color:#fff;
                }
            }

            .kgbdeals{  
                background-color: $colors_concrete_primary;

                p{
                    color:#fff;
                }
            }

            .lucychoi{
                background-color: $colors_lucychoi_primary;

                p{
                    color:white;
                }
            }
            .bupa{
                background: linear-gradient(197.58deg, #3471C1 11.49%, #4987D7 100.62%);
                p{
                    color:#fff;
                }
            }

            .dispo{
                background: lighten(black, 98%);
                p{
                    color:$text;
                }
            }
        
    }//end casestudies
}// end casestudies_container




@media screen and (max-width: 800px) {



    .casestudies_container
    {

    
        .casestudies
        {
            width: 95%;
            grid-template-columns: 1fr;
            grid-row: 200px;
            column-gap: 0px;
            grid-row-gap: 0px;  

            
        
                .casestudy{

                    padding:20px;
                    height:300px;

                }
        }
    }

    .navbar{
        margin-left: 20px;
        margin-right: 20px;
        flex-direction: column;
        margin-bottom: 20px;
    }





 

    .singlecol_content{
        border-left: 20px solid white;
        border-right: 20px solid white;
        

    
        .content{
            width: 90%;
 
    
            .workexperience{
               
                .wexp_entry{
 
                    flex-direction: column;
                    
    
                    .wexp_whenwhere{
                        width: 100%;
                        margin-right: 0px;
                        
                    }
                    .wexp_jobtitle{
                        width: 100%;

                        
                    }
                }
            }
        }
    
        ul{
            margin: 30px 0 30px;
            padding: 20px;
            
        }
    }




    .hero{
        height: 50vh;
        max-height: 400px;
        width: 100vw;
        background-color: #f8f8f8;
        margin-bottom: $sectionspacing;
        overflow: hidden;
        position: relative;
    
        .hero_image{
            display: none;
        }
    
        .hero__content{
            top: 20%;
            left: 10%;
        }
    }



    .container
    {
        width: 90%;
        .section{

            flex-direction: column;
            width: 100%;
    
            .title{
                margin-right: 0px;
                margin-bottom: 30px;
                text-align:center;
            }
        }


        .projectstats{
            ul{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                
                li{
                    width: 100%;
                    margin-bottom: 30px;
                    hr{
                        width: 15%;
                    }
                    p{
                        margin-top: 10px;
                    }
                }
            }
    
        }//end project stats


    }//end container


    .ui_presentaton_panel
    {
        .ui_container{
            flex-wrap: wrap;
            img{
                border-radius: 4px;
                margin-top: 30px;
            }
        }

        .section_2col
        {
            display: flex;
            flex-direction: column;
            width: 90%;
            max-width: 1000px;

            flex-wrap: wrap;
            justify-content: space-between;
            align-items: flex-start;

            .section_1col{
                width: 100%;
                img{
                    display: block;
                    margin: 0 auto;
                    width: 80%;
                }
            }
        }

        .section
        {
            width: 90%;
        }


    }

    footer{
    
        .content{
    
            flex-direction: column;
            align-items: center;
            justify-content: center;

        }
    
    }//end footer

}//end media query