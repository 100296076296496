

@font-face {
    font-family: 'Averta-Regular';
    src: url('../fonts/Averta/Averta-Regular.eot');
    src: url('../fonts/Averta/Averta-Regular.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Averta/Averta-Regular.svg#Averta-Regular') format('svg'),
         url('../fonts/Averta/Averta-Regular.ttf') format('truetype'),
         url('../fonts/Averta/Averta-Regular.woff') format('woff'),
         url('../fonts/Averta/Averta-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'Averta-Semibold';
    src: url('../fonts/Averta/Averta-Semibold.eot');
    src: url('../fonts/Averta/Averta-Semibold.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Averta/Averta-Semibold.svg#Averta-Regular') format('svg'),
         url('../fonts/Averta/Averta-Semibold.ttf') format('truetype'),
         url('../fonts/Averta/Averta-Semibold.woff') format('woff'),
         url('../fonts/Averta/Averta-Semibold.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'Averta-Bold';
    src: url('../fonts/Averta/Averta-Bold.eot');
    src: url('../fonts/Averta/Averta-Bold.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Averta/Averta-Bold.svg#Averta-Bold') format('svg'),
         url('../fonts/Averta/Averta-Bold.ttf') format('truetype'),
         url('../fonts/Averta/Averta-Bold.woff') format('woff'),
         url('../fonts/Averta/Averta-Bold.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}


html{
    -webkit-font-smoothing: subpixel-antialiased;
    -webkit-text-stroke:1px transparent;
    text-rendering: optimizeLegibility;
}


h1,h2,h3,h4,h5,h6, p{
    margin: 0;
    padding: 0;
}


h1,h2,h3,h4,h5,h6{
    display: block;
    font-family: 'Averta-Semibold';
    font-weight: 500;
    font-style: normal;
    font-size: 1rem;
    margin:0;
    padding: 0;
    text-align: left;
    -webkit-font-smoothing: subpixel-antialiased;
    -webkit-text-stroke:1px transparent;
}

h1{
    font-size: 4rem; 
    line-height: 4.8rem;
}
h2{
    font-size: 3.4rem;
    line-height: 4rem;
}
h3{
    font-size: 2.6rem;
    line-height: 3.4rem;
}
h4{
    font-size: 2rem;
}
h5{
    font-size: 1.8rem;
}
h6{
    font-size: 1.6rem;
}




ol{
    margin-left: 10px;
    padding: 0;
}




p, a, li, details{
    font-family: 'Averta-Regular';
    font-weight: normal;
    font-style: normal;
    font-size: 1.8rem;
    line-height: 3rem;
    -webkit-font-smoothing: subpixel-antialiased;
    -webkit-text-stroke:1px transparent;
}


p{
    margin-bottom: 1.6rem;
}


figcaption{
    display: block;
    text-align: center;
    font-size: 1.4rem;
    line-height: 2.4rem;
    background-color: lighten( $colors_concrete_primary, 50% );
    border-radius: 4px;
    color: darken( $colors_concrete_primary, 20% );
    padding: 5px;
    margin-top: 30px;
}

br{
    display: block;
    margin-bottom: 20px;
}

strong{
    font-family: 'Averta-Semibold';
}

::-moz-selection { /* Code for Firefox */
    color: white;
    background:#50C27B;
}
  
::selection {
    color: white;
    background:#50C27B;
}


//When you want to highlight a word
.highlight
{
    color: $colors_superusefulstudio_green; 
}

//when you want to reference a point within a signpost
.signpost_highlight
{
    display: inline-block;
    background-color: $colors_signpost_primary;
    padding: 0 6px;
    color: white;
    border-radius: 4px;
}



.link {
    position: relative;
    color: $colors_superusefulstudio_green;
    text-decoration: none;
  }
  
.link:hover {
    color: $colors_superusefulstudio_green;
}

.link:hover:before {
    visibility: visible;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }

.link:before{
    content: "";
    position: absolute;
    width: 100%;
    height: 4px;
    bottom: -4px;
    left: 0;
    background-color: $colors_superusefulstudio_green;
    visibility: hidden;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transition: all 0.2s ease-in-out 0s;
    transition: all 0.2s ease-in-out 0s;
  }

  
  
